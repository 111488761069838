/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/styles/theme/theme-light/audit/theme.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/@typeform/embed/build/css/widget.css";


/*@media screen and (min-width: 1960px) {
  .layout-content, .landing-wrapper {
    width: unset;
  }

  .layout-topbar {
    width: unset;
  }
}*/

:root {
  --topbar-height: calc(35px + 2rem);
}

.verification-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
  text-align: center;
}

.verification-badge.verification-badge-VALID {
  background-color: #c8e6c9;
  color: #256029;
}

.verification-badge.verification-badge-COMPLETE {
  background-color: #c8e6c9;
  color: #256029;
}

.verification-badge.verification-badge-VERIFIED {
  background-color: #c8e6c9;
  color: #256029;
}

.verification-badge.verification-badge-APPROVED {
  background-color: #c8e6c9;
  color: #256029;
}

.verification-badge.verification-badge-READY_FOR_SUBMISSION {
  background-color: #c8e6c9;
  color: #256029;
}

.verification-badge.verification-badge-DECLINED {
  background-color: #ffcdd2;
  color:#c63737
}

.verification-badge.verification-badge-REJECTED {
  background-color: #ffcdd2;
  color:#c63737
}

.verification-badge.verification-badge-INCOMPLETE {
  background-color: #ffcdd2;
  color:#c63737
}

.verification-badge.verification-badge-DRAFT {
  background-color: #ffcdd2;
  color:#c63737
}

.verification-badge.verification-badge-SUBMITTED {
  background-color: #b3e5fc;
  color:#23547b
}

.verification-badge.verification-badge-UNKNOWN {
  background: #e8e8e8;
  color: #6f6e6e;
}


.layout-content-wrapper {
  // overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.layout-content {
  /*flex-grow: 1;
  overflow: hidden;
  display: flex;*/
  height: calc(100% - var(--topbar-height));
  // overflow: hidden;
}
